<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- CHANGE BACK GL HOSE VERSION -->
	<div class="container-fluid" *ngIf='customerconcern'>
	
	<!-- CHANGE BACK SM VERSION -->
	<!-- <div class="panel panel-primary animaPlaceholder" *ngIf='customerconcern'> -->
	
	<div class='panel panel-primary animaPlaceholder'>
		<div class='panel-heading'>
			<span *ngIf='customerconcern'>{{page}}</span>
			<span class='pull-right'>
				{{record}} of {{recordTotal}}
			</span>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 col-xs-12">
				<div class="row top-bottom-spacing div-status-sml">
					<!-- <div class="col-md-4"> -->
					<div class="col-md-2 col-xs-6 text-right">
						<label> NCR Number: </label>
					</div>
					<div class="col-md-1 col-xs-6">
						<label>{{customerconcern.Id}}</label>
					</div>
					<div class="col-md-1 control-label	col-xs-6">
						<label>NCR Date: </label>
					</div>
					<div class="col-md-4 col-xs-6">
						<input class="form-control date-picker" 
						       type='date' 
							   [ngModel]="ncrDate | date: 'yyyy-MM-dd'"
							   (ngModelChange)="onNCRDateChange($event)" />
					</div>
					<div class="col-md-1 control-label	col-xs-6">
						<label>Status:</label>
					</div>
					<div class="col-md-4 col-xs-12 ">
						<form [formGroup]="StatusForm">
							<select class="form-control" formControlName='StatusID'>
								<option *ngFor='let ccStatus of allCustomerConcernsStatus'
									[value]="ccStatus.StatusID">{{ccStatus.StatusDescription}}</option>
							</select>		
						</form>
					</div>
					<div class="dropdown" *ngIf="customerConcernsId">
						<button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style='width: 110px; margin-right:10px'>
							<span class='glyphicon glyphicon-floppy-save'></span> Report&nbsp;
						</button>
						<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<li><a class="dropdown-item" style="cursor: pointer;" (click)="openReport(1)">Internal</a></li>
							<li><a class="dropdown-item" style="cursor: pointer;" (click)="openReport(2)">Customer</a></li>
							<li><a class="dropdown-item" style="cursor: pointer;" (click)="openReport(3)">Supplier</a></li>
						</ul>
					</div>

					<div class="col-md-1 col-xs-4 control-label">
						<button class='btn btn-link' (click)='openHelp()'>Help</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="row top-bottom-spacing">

				</div>
			</div>
		</div>
		<mat-tab-group (selectedTabChange)="tabClick($event)">
			<mat-tab style="color: #101bb3;" label="NCR Detail">
				<div class='container-fluid'>
					<div class='panel-body'>
						<form class='form-horizontal' novalidate [formGroup]='customerconcernForm'>
							<fieldset>
								<div class='row'>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'>Failure Mode<span style="color:red">*</span></label>
											<div class='col-md-8'>
												<select class="form-control" formControlName='FailureModeLevel1'>
													<option *ngFor='let failureMode of level1FailureModes'
														[value]="failureMode.ID">{{failureMode.Description}}</option>
												</select>												
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'>Sub Failure Mode<span style="color:red">*</span></label>
											<div class='col-md-8'>
											  <select class="form-control" formControlName='FailureModeLevel2'>
												<option *ngFor='let failureMode of level2FailureModes' [value]="failureMode.ID">{{failureMode.Description}}
												</option>
											  </select>
											  <span
													*ngIf="this.customerconcern.NCRCode"
													class="text-muted">
													Legacy NCR Code: {{this.customerconcern.NCRCode}}
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row'>
									<div class='col-md-6'>
									  <div class='form-group'>
										<label class='col-md-4'>Customer<span style="color:red">*</span></label>
										<div class='col-md-8'>
										  <div class="input-group">
											<input #customerInput type='text' class='form-control' 
												   formControlName="CustID"
												   [matAutocomplete]="customerAuto" 
												   [matAutocompleteDisabled]="!customerPanelOpen"
												   minlength="1" 
												   maxlength="250"
												   (keydown)="onCustomerInputKeyDown($event)"
												   matAutocompleteTrigger #customerAutoTrigger="matAutocompleteTrigger"/>
											<span class="input-group-btn">
											  <button class="btn btn-default" 
											          type="button" 
													  (click)="searchCustomers()"
													  style="height: 28px; padding: 3px 12px;">
												<mat-icon>search</mat-icon>
											  </button>
											</span>
										  </div>
										  <mat-autocomplete #customerAuto="matAutocomplete" autoActiveFirstOption [displayWith]="displayCustomerFn.bind(this)" (optionSelected)="onCustomerSelectionMade()">
											<mat-option *ngFor="let item of customersData" [value]="item.CustID">
											  {{item.Name}}
											</mat-option>
										  </mat-autocomplete>
										  <div *ngIf="this.customersDatalength == 0" class="no-results-message">
												No customers found, type at least 3 characters.
										  </div>
										  <span *ngIf="this.customerconcern.Customer" class="text-muted">
												Legacy Customer: {{this.customerconcern.Customer}}
										  </span>
										</div>
									  </div>
									</div>
									<div *ngIf="vendorFieldVisible" class='col-md-6'>
										<div class='form-group'>
										  <label class='col-md-4'>Vendor<span style="color:red">*</span></label>
										  <div class='col-md-8'>
											<div class="input-group">
											  <input #vendorInput type='text' class='form-control' 
													 formControlName="VendorID"
													 [matAutocomplete]="vendorAuto" 
													 [matAutocompleteDisabled]="!vendorPanelOpen"
													 minlength="3" 
													 maxlength="250"
													 (keydown)="onVendorInputKeyDown($event)"
													 matAutocompleteTrigger #vendorAutoTrigger="matAutocompleteTrigger"/>
											  <span class="input-group-btn">
												<button class="btn btn-default" 
														type="button" 
														(click)="searchVendors()"
														style="height: 28px; padding: 3px 12px;">
												  <mat-icon>search</mat-icon>
												</button>
											  </span>
											</div>
											<mat-autocomplete #vendorAuto="matAutocomplete" 
											                  autoActiveFirstOption 
															  [displayWith]="displayVendorFn.bind(this)" 
															  (optionSelected)="onVendorSelectionMade()">
											  <mat-option *ngFor="let vendor of vendorsData" [value]="vendor.VendorID">
												{{vendor.Name}}
											  </mat-option>
											</mat-autocomplete>
											<div *ngIf="customerconcernForm.controls['VendorID'].errors?.minlength" class="text-danger">
											  At least 3 characters are required.
											</div>
											<div *ngIf="this.vendorsDatalength == 0" class="no-results-message">
											  No vendors found.
											</div>
										  </div>
										</div>
									  </div>
								  </div>
								<div class='row'>
									<div class='col-md-6' *ngIf="customerConcernsId">
										<div class='form-group'>
											<label class='col-md-4'> Writer<span style="color:red">*</span></label>
											<span class="col-md-4 d-block">{{this.OwnerName}}</span>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group row'>
											<div class="col-md-4 col-xs-7">
												<div class="row">
													<label class='col-md-4 col-xs-6'> Assign to<span style="color:red">*</span></label>
													<button class="btn btn-primary col-md-3 col-xs-3" *ngIf="customerconcern.Id"
														[disabled]="emailBtnPushed" style="width: 59px;" type="button"
														(click)="sendEmail()">Email
													</button>
													<div class="col-md-5 col-xs-3">
														<input type="checkbox" formControlName="EmailRpt"> Report
													</div>
												</div>
											</div>

											<div class='col-md-8 col-xs-5'>
												<ng-select formControlName='EmployeeAssigned'>
													<ng-option *ngFor="let employee of employees" [value]="employee.Id">
														{{employee.LastName
														+ ', ' + employee.FirstName}}</ng-option>
												</ng-select>
												<span class="text-success">{{emailStatus}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row'>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Branch<span style="color:red">*</span></label>
											<div class='col-md-8'>
												<select class="form-control" formControlName='BranchResponsible'>
													<option *ngFor='let branch of branches;'
														[value]="branch.BranchNumber">
														{{branch.BranchName}} {{branch.BranchNumber}}</option>
												</select>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Division<span style="color:red">*</span></label>
											<div class='col-md-8'>
												<select class="form-control" formControlName='Division'>
													<option value=""></option>		
													<option value="Green Line HF">Green Line HF</option>		
													<option value="Pulsar">Pulsar</option>		
													<option value="GLM - Adapters">GLM - Adapters</option>		
													<option value="GLM - HAL">GLM - HAL</option>		
													<option value="GLM - IAL">GLM - IAL</option>		
													<option value="GLM - Office">GLM - Office</option>		
													<option value="GLM - TAL">GLM - TAL</option>		
													<option value="GLM - Shipping">GLM - Shipping</option>		
												</select>
											</div>
										</div>
									</div>
								</div>
								<div class='row'  *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Cust. Part #</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='CustomerPartNumber' />
												<span
													*ngIf="(customerconcernForm.controls['CustomerPartNumber'].invalid  && customerconcernForm.controls['CustomerPartNumber'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group row'>
											<label class='col-md-4'> Q T Y / U O M </label>
											<div class='col-md-5'>
												<input class='form-control' type='number' formControlName='QTY' placeholder="Q T Y"/>
											</div>
											<div class='col-md-3'>
												<input class='form-control' type='text' formControlName='UOM' placeholder="U O M" style="text-transform: uppercase;"/>
											</div>
										</div>
									</div>
								</div>
								<div class='row'  *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Responsible for </label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='ResponsibleFor'
													placeholder="Departament, Employee Full Name, Machine..." />
												<span
													*ngIf="(customerconcernForm.controls['ResponsibleFor'].invalid  && customerconcernForm.controls['ResponsibleFor'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Hose</label>
											<div class='col-md-8'>
												<input class='form-control' type='text' formControlName='Hose' />
												<span
													*ngIf="(customerconcernForm.controls['Hose'].invalid  && customerconcernForm.controls['Hose'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row' *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Fitting 1</label>
											<div class='col-md-8'>
												<input class='form-control' type='text' formControlName='Fitting1' />
												<span
													*ngIf="(customerconcernForm.controls['Fitting1'].invalid  && customerconcernForm.controls['Fitting1'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Fitting 2</label>
											<div class='col-md-8'>
												<input class='form-control' type='text' formControlName='Fitting2' />
												<span
													*ngIf="(customerconcernForm.controls['Fitting2'].invalid  && customerconcernForm.controls['Fitting2'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row' *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Ferrule</label>
											<div class='col-md-8'>
												<input class='form-control' type='text' formControlName='Ferrule' />
												<span
													*ngIf="(customerconcernForm.controls['Ferrule'].invalid  && customerconcernForm.controls['Ferrule'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Accessory</label>
											<div class='col-md-8'>
												<input class='form-control' type='text' formControlName='Accessory' />
												<span
													*ngIf="(customerconcernForm.controls['Accessory'].invalid  && customerconcernForm.controls['Accessory'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row' *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Ref / S.O. Num(s)</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='OriginalSalesOrderNumber' />
												<span
													*ngIf="(customerconcernForm.controls['OriginalSalesOrderNumber'].invalid  && customerconcernForm.controls['OriginalSalesOrderNumber'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Hose / Fitting Lot #s</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='HoseLotNumbers' />
												<span
													*ngIf="(customerconcernForm.controls['HoseLotNumbers'].invalid  && customerconcernForm.controls['HoseLotNumbers'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row' *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Replacement/Credit S.O.#</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='ReplacementCreditNumber' />
												<span
													*ngIf="(customerconcernForm.controls['ReplacementCreditNumber'].invalid  && customerconcernForm.controls['ReplacementCreditNumber'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Traveler #</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='TravelerNumber' />
												<span
													*ngIf="(customerconcernForm.controls['TravelerNumber'].invalid  && customerconcernForm.controls['TravelerNumber'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row'  *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Internal P/N / DESC</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='HyflexPartNo' />
												<span
													*ngIf="(customerconcernForm.controls['HyflexPartNo'].invalid  && customerconcernForm.controls['HyflexPartNo'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class='row' *ngIf="customerConcernsId">
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Customer Contact</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='CustomerContact' />
												<span
													*ngIf="(customerconcernForm.controls['CustomerContact'].invalid  && customerconcernForm.controls['CustomerContact'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
									<div class='col-md-6'>
										<div class='form-group'>
											<label class='col-md-4'> Customer Complaint Number</label>
											<div class='col-md-8'>
												<input class='form-control' type='text'
													formControlName='CustomerComplaintNumber' />
												<span
													*ngIf="(customerconcernForm.controls['CustomerComplaintNumber'].invalid  && customerconcernForm.controls['CustomerComplaintNumber'].errors.maxlength)"
													class="text-danger">
													Only 250 characters are allowed.
												</span>
											</div>
										</div>
									</div>
								</div>
								<form [formGroup]="descriptionsForm" 
								  novalidate style="display: flex;flex-direction: column;" *ngIf="!customerConcernsId">
									<div class='form-group'>
										<label class='col-md-2 control-label'> Problem<span style="color:red">*</span></label>
										<div class='col-md-10'>
											<textarea class="form-control" formControlName='Problem' rows="6"></textarea>
											<span
												*ngIf="(descriptionsForm.controls['Problem'].invalid  && descriptionsForm.controls['Problem'].errors.maxlength)"
												class="text-danger">
												Only 4000 characters are allowed.
											</span>
										</div>
									</div>
								</form>
							</fieldset>
						</form>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Descriptions" *ngIf="customerConcernsId">
				<div class='panel-body'>
					<form [formGroup]="descriptionsForm" novalidate style="display: flex;flex-direction: column;">
						<div class='form-group'>
							<label class='col-md-2 control-label'> Problem</label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='Problem' rows="6"></textarea>
								<span
									*ngIf="(descriptionsForm.controls['Problem'].invalid  && descriptionsForm.controls['Problem'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Risk Level</label>
							<div class='col-md-2'>
								<select class="form-control" formControlName='RiskLevel'>
									<option value=""></option>		
									<option value="Low">Low</option>		
									<option value="Medium">Medium</option>		
									<option value="High">High</option>		
								</select>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Containment</label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='Containment'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['Containment'].invalid  && descriptionsForm.controls['Containment'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Investigation Notes</label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='investigationResults'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['investigationResults'].invalid  && descriptionsForm.controls['investigationResults'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Root Cause </label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='RootCause'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['RootCause'].invalid  && descriptionsForm.controls['RootCause'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Disposition</label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='Disposition'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['Disposition'].invalid  && descriptionsForm.controls['Disposition'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'>Corrective Action</label>
							<div class="col-md-10">
								<input type="radio" name="CorrectiveAction" value='yes'
									formControlName="CorrectiveAction">
								<label>Yes</label>
								<input type="radio" name="CorrectiveAction" value='no' style="margin-left: 10px;"
									formControlName="CorrectiveAction">
								<label>No</label>
							</div>
						</div>
						<div class='form-group' *ngIf="this.descriptionsForm.value.CorrectiveAction == 'yes'">
							<label class='col-md-2 control-label'> Action Plan</label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='ActionPlan'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['ActionPlan'].invalid  && descriptionsForm.controls['ActionPlan'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group' *ngIf="this.descriptionsForm.value.CorrectiveAction == 'yes'">
							<label class='col-md-2 control-label'> Implementation Notes</label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='ImplementationNotes'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['ImplementationNotes'].invalid  && descriptionsForm.controls['ImplementationNotes'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group' *ngIf="this.descriptionsForm.value.CorrectiveAction == 'yes'">
							<label class='col-md-2 control-label'> Audit of CA after Implementation</label>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='AuditCA'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['AuditCA'].invalid  && descriptionsForm.controls['AuditCA'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group' style="display: none;">
							<div class="col-md-2">
								<label>If Yes, use 'Action form' from QMS; browse for previous action form </label>
								<label class='control-label'> Corrective Action </label>
							</div>
							<div class='col-md-10'>
								<textarea class="form-control" formControlName='CorrectiveActionText'></textarea>
								<span
									*ngIf="(descriptionsForm.controls['CorrectiveActionText'].invalid  && descriptionsForm.controls['CorrectiveActionText'].errors.maxlength)"
									class="text-danger">
									Only 4000 characters are allowed.
								</span>
							</div>
						</div>
						<div class='form-group'>
							<button type="button" class="btn btn-link form-control"
									style="width: 20vw;" type="button" (click)="showFiveWHYsTable()">{{this.fiveWHYsButton}}</button>
							<button type="button" class="btn btn-link form-control"
									style="width: 20vw;" type="button" (click)="showIshikawaDiagram()">{{this.ishikawaDiagramButton}}</button>
						</div>
						<div *ngIf='customerConcernsId && this.fiveWHYsButton=="Hide Five WHYs Table"'>
							<div class='panel-body'>
								<form [formGroup]='FiveWHYsForm' novalidate style="display: flex;flex-direction: column;row-gap: 10px;">
									<div class='form-group'>
										<label class='col-md-1 control-label'> Effect</label>
										<div class='col-md-5'>
											<textarea #effectField class="form-control" formControlName='Effect' rows="2"></textarea>
											<span *ngIf="(FiveWHYsForm.controls['Effect'].invalid  && FiveWHYsForm.controls['Effect'].errors.maxlength)" class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
									</div>
									<div class='form-group'>
										<label class='col-md-1 control-label'> 1 WHY</label>
										<div class='col-md-5'>
											<textarea class="form-control" formControlName='FirstWhy' rows="2"></textarea>
											<span *ngIf="(FiveWHYsForm.controls['FirstWhy'].invalid  && FiveWHYsForm.controls['FirstWhy'].errors.maxlength)" class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
										<label class='col-md-1 control-label'> 2 WHY</label>
										<div class='col-md-5'>
											<textarea class="form-control" formControlName='SecondWhy' rows="2"></textarea>
											<span *ngIf="(FiveWHYsForm.controls['SecondWhy'].invalid  && FiveWHYsForm.controls['SecondWhy'].errors.maxlength)" class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
									</div>
									<div class='form-group'>
										<label class='col-md-1 control-label'> 3 WHY</label>
										<div class='col-md-5'>
											<textarea class="form-control" formControlName='ThirdWhy' rows="2"></textarea>
											<span *ngIf="(FiveWHYsForm.controls['ThirdWhy'].invalid  && FiveWHYsForm.controls['ThirdWhy'].errors.maxlength)" class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
										<label class='col-md-1 control-label'> 4 WHY</label>
										<div class='col-md-5'>
											<textarea class="form-control" formControlName='FourthWhy' rows="2"></textarea>
											<span *ngIf="(FiveWHYsForm.controls['FourthWhy'].invalid  && FiveWHYsForm.controls['FourthWhy'].errors.maxlength)" class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
									</div>
									<div class='form-group'>
										<label class='col-md-1 control-label'> 5 WHY</label>
										<div class='col-md-5'>
											<textarea class="form-control" formControlName='FifthWhy' rows="2"></textarea>
											<span *ngIf="(FiveWHYsForm.controls['FifthWhy'].invalid  && FiveWHYsForm.controls['FifthWhy'].errors.maxlength)" class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
										<label class='col-md-1 control-label'> Action</label>
										<div class='col-md-5'>
											<textarea class="form-control" formControlName='ActionWhy' rows="2"></textarea>
											<span *ngIf="(FiveWHYsForm.controls['ActionWhy'].invalid  && FiveWHYsForm.controls['ActionWhy'].errors.maxlength)" class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
									</div>
									<div class='form-group'>
										<button class="btn btn-primary form-control center-center"
											    style="width: 10vw; float: left; margin-left: 10px; margin-right: 10px;" 
												type="button" 
												(click)="onSaveFiveWHYsTable()">
											Save 5 WHYs Table
										</button>
										<button class="btn btn-info form-control center-center"
											    style="width: 10vw; float: left;" 
												type="button" 
												(click)="clearFiveWHYsForm()">
											Cancel Changes
										</button>
									</div>
								</form>
							</div>
							<div>
								<table mat-table [dataSource]="fiveWHYsDataSource" 
								       class='mat-elevation-z8 table-wdt' 
									   matSort>
									<ng-container matColumnDef="Edit">
										<th mat-header-cell *matHeaderCellDef class='col1'> EDIT </th>
										<td mat-cell *matCellDef="let element"> 
											<button class='btn btn-default '
													style='min-width: 50px;' 
													(click)="editFiveWHYsTableData(element)"> 
												<span style="color: rgb(4, 50, 255);"
													  class="glyphicon glyphicon-edit ">EDIT
												</span>
											</button>
										</td>
									</ng-container>
									<ng-container matColumnDef="Effect">
										<th mat-header-cell *matHeaderCellDef class='col2 wrap-cell-content'>Effect </th>
										<td mat-cell *matCellDef="let element"> {{element.Effect}} </td>
									</ng-container>
									<ng-container matColumnDef="FirstWhy">
										<th mat-header-cell *matHeaderCellDef class='col3 wrap-cell-content'> 1 WHY </th>
										<td mat-cell *matCellDef="let element"> {{element.FirstWhy}} </td>
									</ng-container>
									<ng-container matColumnDef="SecondWhy">
										<th mat-header-cell *matHeaderCellDef class='col4 wrap-cell-content'> 2 WHY </th>
										<td mat-cell *matCellDef="let element"> {{element.SecondWhy}} </td>
									</ng-container>
									<ng-container matColumnDef="ThirdWhy">
										<th mat-header-cell *matHeaderCellDef class='col5 wrap-cell-content'> 3 WHY </th>
										<td mat-cell *matCellDef="let element"> {{element.ThirdWhy}} </td>
									</ng-container>
									<ng-container matColumnDef="FourthWhy">
										<th mat-header-cell *matHeaderCellDef class='col6 wrap-cell-content'> 4 WHY </th>
										<td mat-cell *matCellDef="let element"> {{element.FourthWhy}} </td>
									</ng-container>
									<ng-container matColumnDef="FifthWhy">
										<th mat-header-cell *matHeaderCellDef class='col7 wrap-cell-content'> 5 WHY </th>
										<td mat-cell *matCellDef="let element"> {{element.FifthWhy}} </td>
									</ng-container>
									<ng-container matColumnDef="ActionWhy">
										<th mat-header-cell *matHeaderCellDef class='col8 wrap-cell-content'> Action </th>
										<td mat-cell *matCellDef="let element"> {{element.ActionWhy}} </td>
									</ng-container>
									<ng-container matColumnDef="Delete">
										<th mat-header-cell *matHeaderCellDef class='col9'> Delete </th>
										<td mat-cell *matCellDef="let element">
											<button class='btn btn-default btn-xs'
												style='min-width: 50px;background: none;border: none;'
												(click)="deleteFiveWhysRecord(element)">
												<span class="glyphicon glyphicon-trash text-success text-dlt"></span>
											</button>
										</td>
									</ng-container>
		
									<tr mat-header-row *matHeaderRowDef="displayedColumnsA"></tr>
									<tr mat-row *matRowDef="let row; columns: displayedColumnsA;"></tr>
								</table>
								<mat-paginator #firstPaginator [length]='fiveWHYsRecordTotal' [pageSize]='pageSize'></mat-paginator>
							</div>
						</div>
						<div *ngIf='customerConcernsId && this.ishikawaDiagramButton=="Hide Ishikawa Diagram"'>
							<div class='panel-body'>
								<form [formGroup]='IshikawaDiagramForm' novalidate style="display: flex;flex-direction: column;row-gap: 10px;">
									<div class='form-group'>
										<div id="group1" class='col-md-3'>
											<label control-label>MATERIAL</label>
											<input type='text' class='form-control mb-2' placeholder="Cause 1" formControlName='IshikawaMaterial1' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMaterial1'].invalid && IshikawaDiagramForm.controls['IshikawaMaterial1'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 2" formControlName='IshikawaMaterial2' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMaterial2'].invalid && IshikawaDiagramForm.controls['IshikawaMaterial2'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 3" formControlName='IshikawaMaterial3' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMaterial3'].invalid && IshikawaDiagramForm.controls['IshikawaMaterial3'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 4" formControlName='IshikawaMaterial4' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMaterial4'].invalid && IshikawaDiagramForm.controls['IshikawaMaterial4'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 5" formControlName='IshikawaMaterial5' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMaterial5'].invalid && IshikawaDiagramForm.controls['IshikawaMaterial5'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
										</div>

										<div id="group1" class='col-md-3'>
											<label control-label>MACHINE</label>
											<input type='text' class='form-control mb-2' placeholder="Cause 1" formControlName='IshikawaMachine1' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMachine1'].invalid && IshikawaDiagramForm.controls['IshikawaMachine1'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 2" formControlName='IshikawaMachine2' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMachine2'].invalid && IshikawaDiagramForm.controls['IshikawaMachine2'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 3" formControlName='IshikawaMachine3' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMachine3'].invalid && IshikawaDiagramForm.controls['IshikawaMachine3'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 4" formControlName='IshikawaMachine4' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMachine4'].invalid && IshikawaDiagramForm.controls['IshikawaMachine4'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 5" formControlName='IshikawaMachine5' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMachine5'].invalid && IshikawaDiagramForm.controls['IshikawaMachine5'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
										</div>
										
										<div id="group1" class='col-md-3'>
											<label col-md-1 control-label>METHOD</label>
											<input type='text' class='form-control mb-2' placeholder="Cause 1" formControlName='IshikawaMethod1' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMethod1'].invalid && IshikawaDiagramForm.controls['IshikawaMethod1'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 2" formControlName='IshikawaMethod2' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMethod2'].invalid && IshikawaDiagramForm.controls['IshikawaMethod2'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 3" formControlName='IshikawaMethod3' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMethod3'].invalid && IshikawaDiagramForm.controls['IshikawaMethod3'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 4" formControlName='IshikawaMethod4' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMethod4'].invalid && IshikawaDiagramForm.controls['IshikawaMethod4'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 5" formControlName='IshikawaMethod5' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMethod5'].invalid && IshikawaDiagramForm.controls['IshikawaMethod5'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
										</div>
										
										<div id="group1" class='col-md-3'>
											<label col-md-1 control-label>MAN</label>
											<input type='text' class='form-control mb-2' placeholder="Cause 1" formControlName='IshikawaMan1' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMan1'].invalid && IshikawaDiagramForm.controls['IshikawaMan1'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 2" formControlName='IshikawaMan2' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMan2'].invalid && IshikawaDiagramForm.controls['IshikawaMan2'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 3" formControlName='IshikawaMan3' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMan3'].invalid && IshikawaDiagramForm.controls['IshikawaMan3'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 4" formControlName='IshikawaMan4' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMan4'].invalid && IshikawaDiagramForm.controls['IshikawaMan4'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
											<input type='text' class='form-control mb-2' placeholder="Cause 5" formControlName='IshikawaMan5' />
											<span *ngIf="(IshikawaDiagramForm.controls['IshikawaMan5'].invalid && IshikawaDiagramForm.controls['IshikawaMan5'].errors.maxlength)" class="text-danger">
												Only 70 characters are allowed.
											</span>
										</div>
									</div>
									<label class='col-md-2 control-label'>Issue</label>
										<div class='col-md-5'>
											<textarea class="form-control" formControlName='IssueIshikawaDiagram' rows="2"></textarea>
											<span *ngIf="(IshikawaDiagramForm.controls['IssueIshikawaDiagram'].invalid  && IshikawaDiagramForm.controls['IssueIshikawaDiagram'].errors.maxlength)" 
											      class="text-danger">
												Only 140 characters are allowed.
											</span>
										</div>
									<div class='form-group'>
										<button class="btn btn-primary form-control center-center"
											    style="width: 12vw; float: left; margin-left: 10px; margin-right: 10px;" 
												type="button" 
												(click)="onSaveIshikawaDiagram()">
											Save Ishikawa Diagram
										</button>
										<button class="btn btn-info form-control center-center"
											    style="width: 12vw; float: left; margin-right: 10px;" 
												type="button" 
												(click)="clearIshikawaDiagramForm()">
											Cancel Changes
										</button>
										<button class="btn btn-danger form-control center-center"
											    style="width: 12vw; float: left;" 
												type="button" 
												(click)="deleteIshikawaDiagram()">
											Delete Ishikawa Diagram
										</button>
									</div>
								</form>
							</div>	
						</div>
					</form>

				</div>
			</mat-tab>
			<mat-tab label="C.O.Q." style="width: 50px;" *ngIf="customerConcernsId">
				<div class='panel-body'>
					<form [formGroup]='COQForm' novalidate style="display: flex;flex-direction: column;row-gap: 10px;">
						<div class='form-group'>
							<div class="col-md-2">
								<label class=''> Product/Replacement Cost ($)</label>
							</div>
							<div class="col-md-2">
								<input class="form-control" type="number" formControlName='COQProducts'>
							</div>
						</div>
						<div class='form-group'>
							<div class="col-md-2">
								<label class='control-label'> Admin Fee ($)</label>
							</div>
							<div class="col-md-2">
								<input class="form-control" type="number" formControlName='COQAdminFee'>
							</div>
							<span class="text-muted">($150.00).</span>
						</div>
						<div class='form-group'>
							<div class="col-md-2">
								<label class=''> Labour ($)</label>
							</div>
							<div class="col-md-2">
								<input class="form-control" type="number" formControlName='COQLabour'>
							</div>
								<span class="text-muted">($75.00/hour).</span>
						</div>
						<div class='form-group'>
							<div class="col-md-2">
								<label class=''> Freight ($)</label>
							</div>
							<div class="col-md-2">
								<input class="form-control" type="number" formControlName='COQFreight'>
							</div>
						</div>
						<div class='form-group'>
							<div class="col-md-2">
								<label class=''> Extra charges from customer ($)</label>
							</div>
							<div class="col-md-2">
								<input class="form-control" type="number" formControlName='COQExtra'>
							</div>
						</div>
						<div class='form-group'>
							<div class="col-md-2">
								<label class='control-label'> C.O.Q. Notes</label>
							</div>
							<div class="col-md-4">
								<input class="form-control" type="text" formControlName='COQNotes'>
							</div>
						</div>
						<div class='form-group' *ngIf="this.customerconcern.COQ">
							<label class='col-md-2'> Legacy C.O.Q. </label>
							<div class='col-md-4'>
								<input class='form-control' type='text' formControlName='COQ' readonly/>
							</div>
						</div>
					</form>
				</div>
		</mat-tab>
			<mat-tab label="Attachments" *ngIf="customerConcernsId">
				<div *ngIf="customerConcernsId">
					<div class='panel-body'>
						<form [formGroup]='filesForm' novalidate
							style="display: flex;flex-direction: column;row-gap: 10px;">
							<div>
								<div class="col-md-2">
									<label class='control-label'>File Upload</label>
								</div>
								<div class="col-md-4">
									<input class="form-control" type="file" #inputFile
										(change)="onFileSelected($event)">
									<div *ngIf="fileToEdit && fileToEdit.Id">
										{{fileToEdit.FileName}}
									</div>
									<div *ngIf="fileFormSubmitted && filesForm.controls.fileUpload.errors?.required">
										<span style="color:red">Required</span>
									</div>
								</div>
							</div>
							<div>
								<div class="col-md-2">
									<label class=''> File Description</label>
								</div>
								<div class="col-md-4">
									<input class="form-control" type="text" formControlName='fileDescription'>
									<span
										*ngIf="(filesForm.controls['fileDescription'].invalid  && filesForm.controls['fileDescription'].errors.maxlength)"
										class="text-danger">
										Only 500 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<div class="col-md-2">
									<label class='control-label'> Sort Order</label>
								</div>
								<div class="col-md-4">
									<input class="form-control" type="number" formControlName='sortOrder'>
								</div>
							</div>
							<div class='form-group' style="display:flex;align-items: center;">
								<label class='col-md-2 control-label'> Include in Report</label>
								<div class="col-md-1 text-left">
									<input style="width:34px;" class="form-control" type="checkbox"
										formControlName='includeReport'>
								</div>
							</div>
							<div class="col-md-6 form-group">
								<button class="btn btn-primary form-control center-center remove-width-sml"
									style="width: 10vw;float: right;" type="button" (click)="onSaveFile()">Save
									File</button>
							</div>
						</form>
					</div>
					<div>
						<table mat-table [dataSource]="dataSource" class='mat-elevation-z8 table-wdt' matSort>
							<ng-container matColumnDef="Edit">
								<th mat-header-cell *matHeaderCellDef class='col1'> EDIT </th>
								<td mat-cell *matCellDef="let element"> <button class='btn btn-default '
										style='min-width: 50px;' (click)="editFileData(element)"> <span
											style="color: rgb(4, 50, 255);"
											class="glyphicon glyphicon-edit ">EDIT</span></button></td>
							</ng-container>
							<ng-container matColumnDef="FileName">
								<th mat-header-cell *matHeaderCellDef class='col2'>File Name </th>
								<td mat-cell *matCellDef="let element"> <a href="javascript:void(0)"
										(click)="open(element)"> {{element.FileName}} </a> </td>
							</ng-container>
							<ng-container matColumnDef="Description">
								<th mat-header-cell *matHeaderCellDef class='col3'> Description </th>
								<td mat-cell *matCellDef="let element"> {{element.Description}} </td>
							</ng-container>
							<ng-container matColumnDef="SortOrder">
								<th mat-header-cell *matHeaderCellDef class='col4'> Sort Order </th>
								<td mat-cell *matCellDef="let element"> {{element.sortorder}} </td>
							</ng-container>
							<ng-container matColumnDef="IncludeInReport">
								<th mat-header-cell *matHeaderCellDef class='col5'> Include In Report </th>
								<td mat-cell *matCellDef="let element"> {{element.includeinreport === 1 ? 'True' :
									'False'}}
								</td>
							</ng-container>
							<ng-container matColumnDef="Delete">
								<th mat-header-cell *matHeaderCellDef class='col6'> Delete </th>
								<td mat-cell *matCellDef="let element">
									<button class='btn btn-default btn-xs'
										style='min-width: 50px;background: none;border: none;'
										(click)="deleteFileData(element.Id)">
										<span class="glyphicon glyphicon-trash text-success text-dlt"></span>
									</button>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
						<mat-paginator #firstPaginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Change Log" *ngIf="customerConcernsId">
				<div [hidden]="!dataSourceHistory">
					<table mat-table [dataSource]="dataSourceHistory" class='mat-elevation-z8 table-wdt d-s-h-table'
						matSort>
						<ng-container matColumnDef="ColumnChanged">
							<th mat-header-cell *matHeaderCellDef class='col2'>Column Changed </th>
							<td mat-cell *matCellDef="let element">{{element.ColumnChanged}}</td>
						</ng-container>
						<ng-container matColumnDef="WhatHappened">
							<th mat-header-cell *matHeaderCellDef class='col2'>What Happened </th>
							<td mat-cell *matCellDef="let element">{{element.WhatHappened}}</td>
						</ng-container>
						<ng-container matColumnDef="OldValue">
							<th mat-header-cell *matHeaderCellDef class='col2'>Old Value</th>
							<td mat-cell *matCellDef="let element">{{element.OldValue}}</td>
						</ng-container>
						<ng-container matColumnDef="NewValue">
							<th mat-header-cell *matHeaderCellDef class='col2'>New Value</th>
							<td mat-cell *matCellDef="let element">{{element.NewValue}}</td>
						</ng-container>
						<ng-container matColumnDef="CreatedBy">
							<th mat-header-cell *matHeaderCellDef class='col2'>Created By</th>
							<td mat-cell *matCellDef="let element">{{element.CreatedBy}}</td>
						</ng-container>
						<ng-container matColumnDef="CreatedDate">
							<th mat-header-cell *matHeaderCellDef class='col2'>Created Date</th>
							<td mat-cell *matCellDef="let element">{{element.CreatedDate | date:'dd/MM/YYYY' }}</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="historyDisplayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: historyDisplayedColumns;"></tr>
					</table>

					<mat-paginator #historyPaginator [length]='recordTotalHistory' [pageSize]='pageSize'>
					</mat-paginator>
				</div>
			</mat-tab>
			<mat-tab label="Internal Notes" *ngIf="customerConcernsId">
				<div *ngIf="customerConcernsId">
					<div class='panel-body'>
						<form [formGroup]="notesForm" (ngSubmit)="saveNotes()"
							style="display: flex;flex-direction: column;">
							<div class='form-group'>
								<div class='col-md-12'>
									<textarea class="form-control" formControlName='notes' placeholder="Type notes here"
										rows="6"></textarea>
								</div>
								<div style="margin-left: 15px;" *ngIf="notesFormSubmitted || (notesForm.controls['notes'].invalid && notesForm.controls['notes'].errors && 
								(notesForm.controls['notes'].dirty || notesForm.controls['notes'].touched))">
									<span *ngIf="notesForm.controls['notes'].hasError('required')" style="color: red;">
										Please Enter Notes.
									</span>
									<span *ngIf="notesForm.controls['notes'].hasError('maxlength')" style="color: red;">
										Please Enter 1000 char only
									</span>
								</div>
							</div>
							<div class='form-group'>
								<div class="col-md-2">
									<button class='btn btn-primary' [disabled]='!notesForm.valid||buttonPushed'>
										<span class='glyphicon glyphicon-floppy-save'></span> Add Note
									</button>
								</div>

							</div>
						</form>
					</div>
					<div class="container-fluid">
						<mat-card appearance="outlined">
							<mat-list *ngFor="let item of notesListArray; let j = index">
								<div class="row">
									<div class="col-md-1">
										<button class='btn btn-primary btn-xs mt-2' style='min-width: 50px;'
											(click)="deleteNotes(item.Id)">
											DELETE
										</button>
									</div>
									<div class="col-md-11">
										<div>{{item.Note}}</div>
										<div class="text-right">
											<div>
												<label> Created By : </label>
												<span>{{ item.CreatedBy}}</span>
											</div>
											<div>
												<label> Date : </label>
												<span>{{ item.CreatedDate | date:'MMM MM, YYYY hh-mm'}}</span>
											</div>
										</div>
									</div>

								</div>

								<mat-divider></mat-divider>
							</mat-list>
						</mat-card>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
		<div class='row' style="padding: 10px;">
			<div class='col-md-12'>
				<span>
					<button class='btn btn-primary' (click)='saveCustomerconcern(false)'
						style='width: 110px; margin-right:10px'
						[disabled]='!(customerconcernForm.valid && descriptionsForm.valid)||buttonPushed'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
					</button>
					<button class='btn btn-primary' (click)='saveCustomerconcern(true)'
						style='width: 150px; margin-right:10px'
						[disabled]='!(customerconcernForm.valid && descriptionsForm.valid)||buttonPushed'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
					</button>

				</span>
				<span class='pull-right'>
					<button class='btn btn-primary' (click)='cancel()' style='width: 150px; margin-right:10px'
						[disabled]='buttonPushed'>
						Exit Without Saving &nbsp;
					</button>

				</span>
			</div>
		</div>
		<div class='row' style="padding: 10px;">
			<div class='col-md-12'>
				<span>Updated By: <label *ngIf='customerconcern'>{{customerconcern.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='customerconcern'>{{customerconcern.UpdatedDate}}</label></span>
			</div>
		</div>

	</div>
</div>
<div>
	<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>